/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import style from './mainLayout.module.css';

const MainLayout = ({ children }) => {

  return (
    <>
      <div className={style.siteWrapper}>
        <div className={style.mainLayout}>
          <main className={style.mainMainLayout}>{children}</main>
        </div>
      </div>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;