import React from 'react';

import style from './post.module.css';

export default function PostHeader(props) {
  const { tags, date } = props;
  return <h1>
    { props.children }
    <div className={style.postMetaData}>
      <time className={style.publishDate}>{date}</time>
      <ul className={style.tags}>
        {tags.map((tag) => <li className={style.tag}>{tag}</li>)}
      </ul>
    </div>
  </h1>;
}