import React from 'react';

import style from './post.module.css';
import PostHeader from './PostHeader';

export function Post(props) {
  const { content, date, tags } = props;
  return <div className={style.post}>
    <PostHeader date={date} tags={tags}>
      { props.children }
    </PostHeader>
    <article dangerouslySetInnerHTML={{__html: content }}></article>
  </div>;
}